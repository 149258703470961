import { useEffect, useState }			from 'react'

import	* as API						from '../ressources/API'

import	img_logoCircus					from '../ressources/images/logo-circus.png'
import	img_emptyReplay					from '../ressources/images/replay-empty.png'
import	img_download					from '../ressources/images/icon/icon-download.png'
import	img_logoApperture				from '../ressources/images/logo-apperture-footer.png'

import	img_website                     from '../ressources/images/icon/icon-social-website.png'
import	img_tiktok                      from '../ressources/images/icon/icon-social-tiktok.png'
import	img_facebook					from '../ressources/images/icon/icon-social-facebook.png'
import	img_gmail						from '../ressources/images/icon/icon-social-gmail.png'
import	img_instagram					from '../ressources/images/icon/icon-social-instagram.png'
import	img_whatsapp					from '../ressources/images/icon/icon-social-whatsapp.png'

import	'./style.css'


function	Game() {
	const	[game, set_game] = useState(null)
	const	[id, set_id] = useState(null)
	const	[valid, set_valid] = useState({})

	const	[play, set_play] = useState(null)

	useEffect(() => {
		if (!window.location.search || !window.location.search.includes('?id='))
			return

		const	search = window.location.search.replace('?id=', '')

		set_id(search)

		API.getGame(search)
		.then(response => {
			if (response && response.data) {
				set_game(response.data)

				const	validList = {}

				const	checkMatch = i => {
					if (!response.data.matchs[i])
						return set_valid(validList)

					fetch(`${process.env.REACT_APP_API_URL}/file/matchTeam/${response.data.matchs[i]._id}.mp4`)
					.then(response2 => {
						if (response2.status == 200)
							validList[response.data.matchs[i]._id] = true

						checkMatch(i + 1)
					})
					.catch(() => checkMatch(i + 1))
				}
				checkMatch(0)
			}
		})
	}, [])

	function renderUser(player) {
		return (
			<div className={'gamePictureUser'}>
				<div>
					<img src={`${process.env.REACT_APP_API_URL}/file/user/${player._id}.jpg`} alt={player.name} />
				</div>
				<div>
					<span className={'text bold'}>{player.name}</span>
				</div>
			</div>
		)
	}

	function renderPart(data, count) {
		const	renderRank = []

		if (data.scoresTeam) {
			data.scoresTeam.forEach((score, i) => {
				if (!score || !score.team || !score.team.players)
					return

				let	name = ''

				score.team.players.forEach((player, i2) => name += `${i2 !== 0 ? ' - ' : ''}${player.name}`)

				renderRank.push(
					<div className={'gameRankItem'}>
						<span className={'text'}>{i === 0 ? '1er' : `${i + 1}eme`} : <span className={'text bold'}>{name}</span></span>
					</div>
				)
			})
		}


		return (
			<div className={'gamePartContainer'}>
				<span className={'titleCategory red'}>Partie {count + 1} : {data.gameName}</span>

				<div>
					<div className={'gameVideo'}>
						{valid[data._id] ?
							<video
								controls
								src={`${process.env.REACT_APP_API_URL}/file/matchTeam/${data._id}.mp4`}
								onPlay={() => set_play(data._id)} onPause={() => set_play(null)}
							/>
							:
							<div>
								<img src={img_emptyReplay} alt={''} />
							</div>
						}
						{(valid[data._id] && play !== data._id) && <img src={img_download} alt={'Télécharger'} onClick={() => API.downloadVideo(`${process.env.REACT_APP_API_URL}/file/matchTeam/${data._id}.mp4`, data._id)} />}
					</div>

					<div className={'gameRank'}>
						<span className={'titleRank yellow'}>Classements</span>

						<div>
							{renderRank}
						</div>
					</div>
				</div>
			</div>
		)
	}

	const	playerFound = []
	const	render = []
	const	render2 = []
	let		matchId = null
	let		count = 0

	if (game && game.matchs) {
		game.matchs.forEach(match => {
			if (!match || !match.team || !match.team.players)
				return

			render2.push(renderPart(match, count))
			count++

			if (!matchId)
				matchId = match._id

			match.team.players.forEach(player => {
				if (playerFound.findIndex(find => find === player.name) !== -1)
					return

				playerFound.push(player.name)
				render.push(renderUser(player))
			})
		})
	}

	return (
		<div className={'gameMain'}>

			<div className={'gameHeader'} >
				<div className={'gameLogo'}>
					<img src={img_logoCircus} alt={'Circus'} />
				</div>

				<div className={'gameHeaderTitle'}>
					<span className={'title'}>RESTAURANT • BAR • FLECHETTES</span>
				</div>

				<div className={'gameHeaderText'}>
					{/* <span className={'text'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat</span> */}
				</div>
			</div>

			<div className={'gamePicture'}>
				<span className={'titleCategory'}>• LA FINE ÉQUIPE •</span>

				<div className={'gamePictureText'}>
					{/* <span className={'text'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</span> */}
				</div>

				<div className={'gamePictureTeam'}>
					<img src={`${process.env.REACT_APP_API_URL}/file/matchTeam/${matchId}.jpg`} alt={'équipe'} />
				</div>

				<div className={'gamePictureUserContainer'}>
					{render}
				</div>
			</div>

			<div className={'gamePart'} >
				<span className={'titleCategory'}>• LES HIGHLIGHTS DE LA SOIRÉE •</span>

				{render2}
			</div>

			<div className={'gameSocial'} >
				<span className={'titleCategory'}>• GARDER UNE TRACE DE MA SOIRÉE •</span>

				<span className={'gameSocialText yellow'}>Garder une trace de ma soirée</span>

				<div>
                    <a href={`https://www.circusbordeaux.com/`} target={'_blank'} rel={'noreferrer'}>
						<img src={img_website} alt={'website'} />
					</a>
					<a href={`https://www.tiktok.com/@circusbordeaux`} target={'_blank'} rel={'noreferrer'}>
						<img src={img_tiktok} alt={'tiktok'} />
					</a>
					<a href={`mailto:contact@circusbordeaux.com?body=${window.location.href.replace('?id=', '%3Fid%3D')}`} target={'_blank'} rel={'noreferrer'}>
						<img src={img_gmail} alt={'gmail'} />
					</a>
					<a href={`https://www.instagram.com/circusbordeaux/`} target={'_blank'} rel={'noreferrer'}>
						<img src={img_instagram} alt={'instagram'} />
					</a>
				</div>
			</div>

			<div className={'gameFooter'}>
				<span className={'textFooter'}>Une solution développée par</span>
				<img src={img_logoApperture} alt={'Apperture'} />
			</div>
		</div>
	)
}

export default Game