import { useEffect, useState, useRef }	from 'react'

import	* as API						from '../ressources/API'
import	img_logoCircus					from '../ressources/images/logo-circus.png'
import	img_valid						from '../ressources/images/icon/icon-valid.png'
import	img_arrow						from '../ressources/images/icon/icon-arrow.png'
import	img_profile						from '../ressources/images/icon/icon-profile.png'

import './style.css'

function Form() {
	const 	ref = useRef()
	const	[game, set_game] = useState(null)
	const	[id, set_id] = useState(null)

	const	[actual, set_actual] = useState(0)
	const	[users, set_users] = useState([{email: '', name: '', date: '', postalCode: ''}])

	const	[actualInput, set_actualInput] = useState(null)

	const	[check, set_check] = useState(false)

	useEffect(() => {
		if (!window.location.search || !window.location.search.includes('?id='))
			return

		const	search = window.location.search.replace('?id=', '')

		set_id(search)

		API.getGame(search)
		.then(response => {
			if (response && response.data)
				set_game(response.data)
		})
	}, [])

	function sendApi() {
		API.postGame(id, users)
		.then(response => {
			if (response && response.status)
				set_game(null)
		})
	}

	function addUser() {
		const	copy = [...users]

		copy.push({email: '',name: '',date: '',postalCode: ''})

		set_users(copy)
		set_actual(users.length)
	}

	const validateEmail = email => /\S+@\S+\.\S+/.test(email)
	const validateName = name => /^[ a-zA-Z\-\’]+$/.test(name)
	const validateZip = postalCode => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(postalCode)

	function renderInput(i, name, placeholder) {
		const	onChange = val => {
			const	copy = [...users]

			copy[i][name] = val.target.value

			set_users(copy)
		}

		let	error = false
		let	valid = false

		if (name === 'email' && users[i][name]) {
			error = !validateEmail(users[i][name])
			valid = !error
		}

		if (name === 'name' && users[i][name]) {
			error = !validateName(users[i][name])
			valid = !error
		}

		if (name === 'date' && users[i][name])
			valid = true

		if (name === 'postalCode' && users[i][name]) {
			error = !validateZip(users[i][name])
			valid = !error
		}

		return (
			<input
				ref={name === 'date' ? ref : null}
				className={`text ${(error && actualInput !== name) && 'formInputError'} ${(valid && actualInput !== name) && 'formInputValid'}`}
				type={'text'}
				value={users[i][name]}
				onChange={onChange}
				placeholder={placeholder}

				onFocus={(e) => {
					set_actualInput(name)

					if (name === 'date')
						ref.current.type = 'date'
				}}
				onBlur={() => {
					set_actualInput(null)
					// if (name === 'date')
					// 	ref.current.type = 'text'
				}}
			/>
		)
	}

	function renderUser(i) {
		if (actual === i) {
			return (
				<div className={'formUserContainer'}>
					<div className={'formUserTitle'}>
						<span className={'text bold'}>UTILISATEUR N°{i + 1}</span>
					</div>

					<div className={'formUserInput'}>
						{renderInput(i, 'email', 'exemple@circus.com *')}
						{renderInput(i, 'name', 'Prénom *')}
						{renderInput(i, 'date', 'Date de naissance (facultatif)')}
						{renderInput(i, 'postalCode', 'Code Postal (facultatif)')}
					</div>

					<span className={'formUserText'}>* Champs obligatoire</span>
				</div>
			)
		}

		return (
			<div className={'formUserContainer close'} onClick={() => set_actual(i)}>
				<div className={'formUserContainerImage'}>
					<img src={img_profile} alt={''} />
				</div>
				<div className={'formUserContainerInfo'}>
					<span className={'text bold'}>UTILISATEUR N°{i + 1}</span>
					<span className={'text'}>{users[i].email}&nbsp;</span>
				</div>
				<div className={'formUserContainerIcon'}>
					<img src={img_arrow} alt={''} />
				</div>
			</div>
		)
	}

	const	render = []
	let		isValid = true

	users.forEach((e, i) => {
		render.push(renderUser(i))

		if (!e.email || !e.name)
			isValid = false
		else if (!validateEmail(e.email) || !validateName(e.name))
			isValid = false
		else if (e.postalCode && !validateZip(e.postalCode))
			isValid = false
	})

	return (
		<div className={'formMain'}>
			<div className={'formLogo'}>
				<img src={img_logoCircus} />
			</div>

			<div className={'formText'}>
				<span className={'titleCategory'}>•&nbsp;&nbsp;M E R C I&nbsp;&nbsp;•</span>
				<span className={'textBig'}>D'AVOIR PASSÉ LA SOIRÉE AVEC NOUS</span>
				{game && <span className={'text'}>Compléter ce formulaire pour recevoir le récap !</span>}
			</div>

			{game && render}

			{game &&
				<div className={`formConfirm ${check && 'active'}`} onClick={() => set_check(!check)} >
					<div>
						{check && <img src={img_valid} alt={''} />}
					</div>
					<span className={'text'}>En cochant cette case j'accepte les <a>conditions générales d'utilisation</a></span>
				</div>
			}

			{game &&
				<div className={`formButton ${(check && isValid) && 'active'}`} onClick={() => (check && isValid) && sendApi()}>
					<span className={'text bold'}>JE VEUX LE RÉCAP' DE MA SOIRÉE !</span>
				</div>
			}

			{game &&
				<div className={'formAddUser'}>
					<span className={'text red underline'} onClick={addUser}>Ajouter un utilisateur</span>
				</div>
			}

			<br />

		</div>
	)
}

export default Form;